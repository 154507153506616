import React from "react";
import { RouteObject } from "react-router-dom";
import { Ares } from "../layouts/Ares";


import { ShipmentsManage } from "../pages/ares/m24l/InboundShipments/ShipmentsManage";
import { DashW } from "../pages/ares/m24l/Warehouse/DashW";
import { StockMovementsIn } from "../pages/ares/m24l/Warehouse/StockMovementsIn";
import { StockMovementOut } from "../pages/ares/m24l/Warehouse/StockMovementsOut";
import { DashIS } from "../pages/ares/m24l/InboundShipments/DashIS";
import { StockMaster } from "../pages/ares/m24l/Warehouse/StockMaster";
import { StockAdjustments } from "../pages/ares/m24l/Warehouse/StockAdjustments";
import { Lock } from "lucide-react";
import { StoreManagement } from "../pages/ares/m24l/Retail/StoreManagement";
import { ReturnRequest } from "../pages/ares/m24l/Warehouse/ReturnRequest";
import { WholesaleMovementOut } from "../pages/ares/m24l/Warehouse/WholesaleMovementOut";
import { Notification } from "../component/solution/Notification";

export const AresRoutes: RouteObject[] = [
	{
		path: "/",
		element: <Ares />,
		children: [
			{
				path: "",
				element: <DashIS />,
			},
		],
	},
	{
		path: "shipments",
		element: <Ares />,
		children: [
			{
				path: "dashboard",
				element: <DashIS />,
			},
			{
				path: "manage",
				element: <ShipmentsManage />,
			},					
		],
	},
	{
		path: "m24l",
		element: <Ares />,
		children: [
			{
				path: "dash",
				element: <DashW />,
			},				
			{
				path: "notifications-new",
				element: <Notification />,
			},							
			{
				path: "stock-adjustment",
				element: <StockAdjustments />,
			},	
			{
				path: "stock-master",
				element: <StockMaster />,
			},
			{
				path: "wholesale-movement-out",
				element: <WholesaleMovementOut />,
			},	
			{
				path: "return-request",
				element: <ReturnRequest />,
			},									
			{
				path: "stock-movement-in",
				element: <StockMovementsIn />,
			},
			{
				path: "stock-movement-out",
				element: <StockMovementOut />,
			},
		],
	},
	{
		path: "ecommerce",
		element: <Ares />,
		children: [
			{
				path: "stock-items",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
			{
				path: "sales-invoice",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
		],
	},
	{
		path: "retail",
		element: <Ares />,
		children: [
			{
				path: "store-management",
				element: <StoreManagement/>,
			},
		],
	}
];

