import React, { useState, useRef, useEffect } from 'react'
import { Card, CardBody, Modal, Button } from "react-bootstrap";
import { useAuth } from '../../contexts/AuthContext';
import { formatDateFull } from '../solution/utils/AppUtil';
import { CastType, IQuery, IWhere } from '../solution/Interfaces';
import ShowErrorAlert  from '../ShowErrorAlert';
import { color } from 'echarts';

interface IStore {
  id: string;
  code: string;
  name: string;
  email: string
  customer_code: string;
  costing_code: string;
  sit_location: string;
  shopify_location_id: string;
  created: string;
  updated: string| null;
}

interface IComponent {
  store: IStore;
  close: () => void;
  isCycleCount?: boolean; // Optional boolean prop
}

interface IStockTake {
  id: string;
  location: string;
  comment: string | null;
  seq: number;
  reference: string;
  created_by: string;
  created: string;
  updated: string;
  initialised: string | null;
  finalised: string | null;
  last_session_num: number;
  deleted_by: string | null;
  is_cycle_count: boolean;
  status: string;
}

interface IStockTakeLine {
  id: string;
  stocktake_id: number;
  created_by: string;
  created: string;
  updated: string;
  session_num: number | null;
  item_code: string; 
  qty: number| null;
}

interface IStockReportReview {
  actual_variance : number;
  available_start : number;
  committed_final : number;
  committed_start : number;
  item_code: string;
  location: string;
  name: string;
  on_hand_final: number;
  on_hand_start: number;
  reference: string;
  scanned_qty: number;
  scanned_variance: number;
  stocktake_id : string;
}


const StockTake:React.FC<IComponent | null> = (props) => {

  const { fetchData, responseErrorMessage, showErrorAlert, responseStatus }  = useAuth();
  const queryUrl: string = `${process.env.REACT_APP_BASE_URL}/${"query/data"}`;
  const fetchStockTakeActionsQueryUrl: string = `${process.env.REACT_APP_BASE_URL}/functions/STOCKTAKE-ACTIONS/run2`; 
  const fetchStockTakeSessionActionsQueryUrl: string = `${process.env.REACT_APP_BASE_URL}/functions/STOCKTAKE-SESSION-ACTIONS/run2`; 
  const fetchStockTakeLineActionsQueryUrl: string = `${process.env.REACT_APP_BASE_URL}/functions/STOCKTAKE-LINE-ACTIONS/run2`; 

  const [stockTakeState, setStockTakeState] = useState<IStockTake[]>([]);
  const [stockTakeLineState, setStockTakeLineState] = useState<IStockTakeLine[]>([]);
  const [selectedStockTake, setSelectedStockTake] = useState<IStockTake | null>(null);
  const [selectedReportReview, setSelectedReportReview] = useState<IStockReportReview[]>([]);
 
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showReportPreviewModal, setShowReportPreviewModal] = useState<boolean>(false);
  const [showCloseRecordModal, setShowCloseRecordModal] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<'file' | 'scan' | 'default'>('default'); 
  const [comment, setComment] = useState<string>(''); // Separate state for comment

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const scanRef = useRef<HTMLInputElement | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const commentRef = useRef<HTMLTextAreaElement | null>(null);

  const [submitRefreshData, setSubmitRefreshData] = useState<boolean>(false); 
  const [submitRefreshLineData, setSubmitRefreshLineData] = useState<boolean>(false);
  
  const [currentSession, setCurrentSession] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [downLoadLoading, setDownLoadLoading] = useState<boolean>(false);
  const [hasBeenCompleted, setHasBeenCompleted] = useState<boolean>(false);



  const defaultWhere: IWhere[] = [
    {
        fullColumnName: "location",
        value: props?.store.code,
        operator: "EQ",
        cast: CastType.STRING
    },            
    {
        fullColumnName: "status",
        value: ["CLOSED", "OPEN"],
        operator: "IN",
        cast: CastType.STRING
    },
    {
      fullColumnName: "is_cycle_count",
      value: props?.isCycleCount,
      operator: "IN",
      cast: CastType.STRING
    }         
  ];

  //Query State
  const [queryState, setQueryState] = useState<IQuery>({
    tableName: "sa_stocktake",
    select: [],
    groupBy: [],
    join: [],
    orderBy: [ 
        {
            fullColumnName: "created",
            direction: "ASC",
            cast: CastType.TIMESTAMP
        }
    ],
    where: defaultWhere,
    mainTableOnly: false,
    includeExtraColumns: true,
    includeControlColumns: true,
    modifiedAfter: null,
    touchedBefore: null,
    isDirty: null,
    pageNum: 0,
    pageSize: 100,
    frontEndOnly: null
  });

 //Query Line State
 const [queryLineState, setQueryLineState] = useState<IQuery>({
    tableName: "sa_stocktake_line",
    select: [],
    groupBy: [],
    join: [],
    orderBy: [ 
        {
            fullColumnName: "created",
            direction: "ASC",
            cast: CastType.TIMESTAMP
        }
    ],
    where:[
        {
            fullColumnName: "stocktake_id",
            value: selectedStockTake?.id, 
            operator: "EQ",
            cast: CastType.UUID
        }
    ],
    mainTableOnly: false,
    includeExtraColumns: true,
    includeControlColumns: true,
    modifiedAfter: null,
    touchedBefore: null,
    isDirty: null,
    pageNum: 0,
    pageSize: 100,
    frontEndOnly: null
 });



 useEffect(() => {
  setLoading(true); 
  fetchData(queryUrl, {method:"POST", body: JSON.stringify(queryState)}, processData, cleanUpLoader);
  }, [queryState, submitRefreshData]);

  useEffect(() => {
    const queryLine = {...queryLineState}
    queryLine.where = [
        {
            fullColumnName: "stocktake_id",
            value: selectedStockTake?.id,
            operator: "EQ",
            cast: CastType.UUID
        },{
          fullColumnName: "session_num",
          value: currentSession, 
          operator: "EQ",
          cast: CastType.NUMERIC
        }           
    ]
    setLoading(true); 
    fetchData(queryUrl, {method:"POST", body: JSON.stringify(queryLine)}, processLineData, cleanUpLoader);
  }, [selectedStockTake, submitRefreshLineData]);

  useEffect(() => {
    if (selectedStockTake && scanRef.current) {
        scanRef.current.focus();
    }
    if (selectedStockTake && fileRef.current) {
        fileRef.current.focus();
    }
  }, [selectedStockTake]);

  useEffect(() => {
    if (commentRef.current) {
        commentRef.current.focus();
    }
  }, [showCloseRecordModal])

  //default clean up loader
  const cleanUpLoader = () => {
    setLoading(false);
  }

  //default report up loader
  const cleanUpReportLoader = () => {
      setReportLoading(false);
  }

  //default complete stock take / cycle count loader
  const cleanUpLoaderComplete = () => {
    setLoading(false);
    setHasBeenCompleted(false);
    setComment("");
  }

  //default download loader
  const cleanUpLoaderDownLoad = () => {
    setDownLoadLoading(false);
  }

//processing data 
const processData = (result: any) => {
   const stockTakes: IStockTake[] = [];
   //console.log(result);
        
   for(const key in result?.data){
       stockTakes.push({...result.data[key].data, ...result.data[key].control, ...result.data[key].undefined});
   }

   stockTakes.sort((a, b) => {
    if (a.status === "OPEN" && b.status !== "OPEN") {
        return -1; // "OPEN" goes to the top
    }
    if (a.status !== "OPEN" && b.status === "OPEN") {
        return 1; // "OPEN" goes to the top
    }
    return 0; // If both have the same status, maintain the original order
   });

   setStockTakeState(stockTakes);
};

//processing line data
const processLineData = (result: any) => {
  const stockTakeLines: IStockTakeLine[] = [];

  for(const key in result?.data){
     stockTakeLines.push({...result.data[key].data, ...result.data[key].control, ...result.data[key].undefined});
  }
  setStockTakeLineState(stockTakeLines);
};


  //process of creating a stock take
  const handleCreateStockTakeSubmit = () => {
    if (props?.store?.code) {
      setLoading(true); 
      fetchData(fetchStockTakeActionsQueryUrl, 
        {method:"POST", body: JSON.stringify({ location: props?.store.code, is_cycle_count: props.isCycleCount })}, 
        (result: any) =>  { 
            processData(result);
            if(responseStatus === "200"){
              // Causes refresh on data  
              setSubmitRefreshData(prev => !prev);
            }
        }, cleanUpLoader);
    }else{
      return; // Prevent further execution if the code is not available
    }
  };


   //DELETE STOCK TAKE Record
   const handleDeleteSubmit = (stockTakeID: string) => {
      if(stockTakeID){
        setLoading(true); 
        fetchData(fetchStockTakeActionsQueryUrl,
          { method: "DELETE", body: JSON.stringify({ stocktake_id: stockTakeID })}, 
          (result: any) =>  { 
              processData(result);
              if(responseStatus === "200"){
                //Causes refresh on data 
                setSubmitRefreshData(prev => !prev);
              }
          }, cleanUpLoader); 
      }else{
        return;
      }
   };

   //CLOSE STOCK TAKE Record 
   const handleCloseSubmit = (stockTakeID: string) => {
      if(stockTakeID){
      setLoading(true); 
      setHasBeenCompleted(true);
      //console.log(`This is what im posting: { stocktake_id: ${stockTakeID}, comment: ${comment} }`);
      fetchData(fetchStockTakeActionsQueryUrl,
        { method: "PUT", body: JSON.stringify({ stocktake_id: stockTakeID, comment: comment })}, 
        (result: any) =>  { 
           processData(result);
           if(responseStatus === "200"){
              //Causes refresh on data 
              setSubmitRefreshData(prev => !prev);
           }
        }, cleanUpLoaderComplete); 
    }else{
      return;
    } 
   };


   //SCANNING BARCODES
   // Add the event handler for "Enter" key press
   const handleScanKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, stockTakeID: string) => {
    if (event.key === 'Enter') {
        event.preventDefault();  // Prevent default behavior (if needed)
        handleScanSubmit(stockTakeID); 
    }
   };


  const handleScanSubmit = (stockTakeID: string) => {
     const barcode = scanRef.current?.value.trim();
     if (barcode && currentSession) {
        setLoading(true); 
        fetchData(fetchStockTakeLineActionsQueryUrl,
          { method:"POST", body: JSON.stringify({ stocktake_id: stockTakeID, session_num: currentSession, barcode: barcode})}, 
          (result: any) =>  { 
             processLineData(result);
             // Causes refresh on line data 
             setSubmitRefreshLineData(prev => !prev);
          }, cleanUpLoader); 

        // Reset scanRef after submission
        if (scanRef.current) {
            scanRef.current.value = "";
        }  
     }else{
       return
     }
  };


  //FILE WITH BARCODES
  // Handle file/folder selection
  const handleFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Select the first file (if any)
    if (file) {
        setSelectedFile(file); // Set the selected file
    } else {
        setSelectedFile(null); // Handle case where no file is selected
    }
  };  


  const handleFileSubmit = (stockTakeID: string) => {
     if(selectedFile){
       // how to do a multipe part form posts javascript  
       const formData = new FormData();
       formData.append('f', selectedFile);
       formData.append('j1', new Blob([JSON.stringify({ stocktake_id: stockTakeID })], { type: 'application/json' }));

       const headers = new Headers();
       headers.append("Content-Type", "multipart/formdata");

       setLoading(true); 
       fetchData(fetchStockTakeSessionActionsQueryUrl, 
        { method:"POST", body: formData, headers: headers }, 
        ((result: any) =>  { 
          processLineData(result);
          // Causes refresh on line data 
          setSubmitRefreshLineData(prev => !prev);
         }), cleanUpLoader); 

       // Reset fileRef after submission
       setSelectedFile(null);
       if (fileRef.current) {
          fileRef.current.value = ''; 
       }

       setViewMode('default');
     }else{
      return;
     }
  };
  //end point for getting a report of stock take. 


  //handle close view 
  const handleCloseView = (stocktake:IStockTake) => {
    setSelectedStockTake(stocktake)
    setComment(selectedStockTake?.comment ?? "");
    setShowCloseRecordModal(true);
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value); 
  };


   //handle delete screen view 
   const handleDeleteView = (stocktake:IStockTake) => {
    setSelectedStockTake(stocktake)
    setShowModal(true);
  };

  //handle file screen view 
  const handleFileView = (stocktake: IStockTake) => {
    setViewMode('file');
    setSelectedStockTake(stocktake);
  };

  //handle scan screen view 
  const handleScanView = (stocktake: IStockTake) => {
    setViewMode('scan');
    setSelectedStockTake(stocktake);

    setLoading(true); 
    fetchData(fetchStockTakeSessionActionsQueryUrl, 
      {method:"POST", body: JSON.stringify({ stocktake_id: stocktake.id })}, 
      (result: any) =>  { 
        setCurrentSession(result?.session_num);
      }, cleanUpLoader);
  };

  //handle report screen view 
  const handleReportView = async (stocktake: IStockTake) => {
    setSelectedStockTake(stocktake)
    setShowReportPreviewModal(true);

    // const conditionalWhereClause = props?.isCycleCount && {
    //   fullColumnName: "scanned_qty",
    //   value: 0,
    //   operator: 'GT',
    //   cast: CastType.INTEGER
    // }; 

    const reportPreviewLineQuery: IQuery = {
        tableName: "sa_stocktake_report",
        select: [],
        groupBy: [],
        join: [],
        orderBy: [ 
            {
                fullColumnName: "name",
                direction: "ASC",
                cast: CastType.STRING
            }
        ],
        where:[
            {
                fullColumnName: "stocktake_id",
                value: stocktake.id, 
                operator: "EQ",
                cast: CastType.UUID
            },
            ...(props?.isCycleCount ? [{
              fullColumnName: "scanned_qty",
              value: 0,
              operator: 'GT',
              cast: CastType.INTEGER
          }] : []) // Add conditionally based on isCycleCount
        ],
        mainTableOnly: false,
        includeExtraColumns: true,
        includeControlColumns: true,
        modifiedAfter: null,
        touchedBefore: null,
        isDirty: null,
        //pageNum: 0,
        //pageSize: 100,
        frontEndOnly: null
      }

    setReportLoading(true);
    fetchData(queryUrl, {method:"POST", body: JSON.stringify(reportPreviewLineQuery)}, (result: any) => processReportData(result), cleanUpReportLoader);
  };

  const processReportData = (result: any) => {
    const reportPreview: IStockReportReview[] = [];
         
    for(const key in result?.data){
        reportPreview.push({...result.data[key].data,});
    }
 
    setSelectedReportReview(reportPreview);
  };

  //handling reporting down
  const handleReportDownLoad = (selectedStockTake:IStockTake) => {

     setDownLoadLoading(true);
     fetchData(`${fetchStockTakeActionsQueryUrl}?stocktake_id=${selectedStockTake.id}`, {method: "GET" }, (result: any) =>  processStockTakeReportExcel(result, selectedStockTake.reference), cleanUpLoaderDownLoad);
  }


  const processStockTakeReportExcel = async (result: any, stockTakeReference: string) => {
    
    // Create a download link for the Blob
    const url = URL.createObjectURL(result);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    //a.download = `${confirmShipmentNo}.xlsx`; // Set the default filename here
    a.download = `${stockTakeReference}.xlsx`; // Set the default filename here
    document.body.appendChild(a);
    a.click();
 } 

  const handleBackToStockTakes = () => {
    //handling the resetting to default view
    setViewMode('default');
    setSelectedStockTake(null);
    setCurrentSession(null); //reset session
  };


  //Performing check to determine if all stock takes / cycles are all closed. 
  const allClosed = stockTakeState.every(item => item.status === 'CLOSED');

  //Performing Total Calculations
  const totalOnHandStart = selectedReportReview.reduce((total, report) => total + report.on_hand_start, 0);
  const totalCommittedStart = selectedReportReview.reduce((total, report) => total + report.committed_start, 0);
  const totalAvailableStart = selectedReportReview.reduce((total, report) => total + report.available_start, 0);
  const totalScannedQty = selectedReportReview.reduce((total, report) => total + report.scanned_qty, 0);
  const totalScannedVarience = selectedReportReview.reduce((total, report) => total + report.scanned_variance , 0);
  const totalOnHandFinal = selectedReportReview.reduce((total, report) => total + report.on_hand_final , 0);
  const totalCommittedFinal = selectedReportReview.reduce((total, report) => total + report.committed_final, 0);
  const totalActaulVarience = selectedReportReview.reduce((total, report) => total + report.actual_variance, 0);


  //console.log(selectedStockTake);

  return (
    <>
        { showErrorAlert && <ShowErrorAlert message={responseErrorMessage} /> } 
         <Card>
                <Card.Header>
                    <Card.Title>{` ${props?.isCycleCount ? "Cycle Count" : "Stock Take"} - [${props?.store.name}]`}</Card.Title>                       
                </Card.Header>
                <CardBody>
                  <div className="d-flex gap-1">
                      <button
                        className="btn btn-secondary h-100"
                        style={{width: viewMode === 'default' ? "120px" : "150px"}}
                            onClick={() => {
                                if (viewMode === 'default') {
                                    props?.close(); 
                                    setSelectedStockTake(null);
                                } else {
                                    handleBackToStockTakes();
                                }
                            }}
                       >
                      { viewMode === 'default' ? "Back to Stores" : "Back To Stock Takes" }
                     </button>
                     
                     { viewMode === "default" && allClosed &&
                      <button
                          className="btn btn-secondary h-100"
                          
                          onClick={() => {handleCreateStockTakeSubmit()}}
                        >
                         {props?.isCycleCount === true && "Create Cycle Count" } 
                         {props?.isCycleCount === false && "Create Stock Take" } 
                      </button>
                     }
                    
                  </div>
                  <br></br>                                       
                  <div>
                    { /* Stock Take Table */ }
                    <table className="table table-bordered">
                            <thead>                           
                                <tr>
                                    <td><b>Reference</b></td>
                                    <td><b>Location</b></td>
                                    <td><b>Status</b></td>
                                    <td><b>Created</b></td>
                                    <td><b>Comment</b></td>
                                    <td><b>Action</b></td>
                                </tr>
                            </thead>
                            <tbody>
                            {loading && viewMode === "default" ? (
                              <>
                              <tr>
                                <td colSpan={6} className="text-center">
                                  <div className="spinner-border" role="status">
                                     <span className="visually-hidden">Loading...</span>
                                  </div>
                                </td>
                              </tr>
                              {hasBeenCompleted && 
                                <tr style={{border:"none"}}>
                                 <td colSpan={6} className="text-center">
                                  <span className="" style={{fontSize:"15px", fontWeight:"600"}} >We are currently processing your request...</span>
                                 </td>
                                </tr>
                               }
                               </>
                            ) : (
                              <>
                                {viewMode === "default" &&
                                  stockTakeState.map((stakes: IStockTake) => (
                                    <tr key={stakes.id}>
                                      <td>{stakes.reference}</td>
                                      <td>{stakes.location}</td>
                                      <td
                                        className={`text-white text-center ${
                                          stakes.status === "OPEN"
                                            ? "bg-success"
                                            : stakes.status === "CLOSED"
                                            ? "bg-warning"
                                            : ""
                                        }`}
                                      >
                                        {stakes.status}
                                      </td>
                                      <td>{formatDateFull(stakes.created)}</td>
                                      <td>{stakes.comment}</td>
                                      <td className="text-center">
                                        {stakes.status === "OPEN" && (
                                          <>
                                            <button
                                              className="btn btn-secondary"
                                              style={{ width: "100px", margin: "1px" }}
                                              onClick={() => handleFileView(stakes)}
                                            >
                                              File
                                            </button>
                                            <button
                                              className="btn btn-secondary"
                                              style={{ width: "100px", margin: "1px" }}
                                              onClick={() => handleScanView(stakes)}
                                            >
                                              Scan
                                            </button>
                                          </>
                                        )}
                                        <button
                                          className="btn btn-secondary"
                                          style={{ width: "100px", margin: "1px" }}
                                          onClick={() => handleReportView(stakes)}
                                        >
                                          Report
                                        </button>
                                        {stakes.status === "OPEN" && (
                                          <>
                                            <button
                                              className="btn btn-success"
                                              style={{ margin: "1px" }}
                                              onClick={() => handleCloseView(stakes)}
                                            >
                                              <span style={{ fontSize: "13px" }}>✔</span>
                                            </button>
                                            <button
                                              className="btn btn-danger"
                                              style={{ margin: "1px" }}
                                              onClick={() => handleDeleteView(stakes)}
                                            >
                                              <span style={{ fontSize: "13px" }}>✘</span>
                                            </button>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                {/* selected stock take views */}
                                {selectedStockTake && viewMode !== "default" && (
                                  <>
                                    <tr key={selectedStockTake.id}>
                                      <td>{selectedStockTake.reference}</td>
                                      <td>{selectedStockTake.location}</td>
                                      <td
                                        className={`text-white text-center ${
                                          selectedStockTake.status === "OPEN"
                                            ? "bg-success"
                                            : selectedStockTake.status === "CLOSED"
                                            ? "bg-warning"
                                            : ""
                                        }`}
                                      >
                                        {selectedStockTake.status}
                                      </td>
                                      <td>{formatDateFull(selectedStockTake.created)}</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={7} style={{ textAlign: "center" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span style={{ marginRight: "10px" }}>
                                            {viewMode === "scan" && "Scan Code:"}
                                            {viewMode === "file" && "Upload File:"}
                                          </span>
                                          {viewMode === "scan" && (
                                            <input
                                              ref={scanRef}
                                              id="scan_code"
                                              name="scan_code"
                                              type="text"
                                              autoComplete="off"
                                              className="form-control form-control-md w-25"
                                              style={{ margin: 0 }}
                                              onKeyDown={(e) => handleScanKeyDown(e, selectedStockTake?.id)}
                                            />
                                          )}
                                          {viewMode === "file" && (
                                            <>
                                              <input
                                                ref={fileRef}
                                                id="file_codes"
                                                name="file_codes"
                                                type="file"
                                                className="form-control form-control-md w-25"
                                                style={{ margin: 0 }}
                                                onChange={handleFolderChange}
                                              />
                                              <button
                                                className="btn btn-light"
                                                style={{
                                                  width: "120px",
                                                  margin: "4px",
                                                  border: "1px solid #d3d3d3",
                                                }}
                                                disabled={!selectedFile}
                                                onClick={() => handleFileSubmit(selectedStockTake?.id)}
                                              >
                                                Submit File
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </>
                            )}
                          </tbody>

                            
                      </table>

                      {selectedStockTake && viewMode !== "default" && viewMode === "scan" &&
                        <table className="table table-bordered">
                            <thead>                           
                                <tr>
                                    <td><b>Item Code</b></td>
                                    <td><b>Created</b></td>
                                    <td><b>Qty</b></td>
                                </tr>                                                             
                            </thead>
                            <tbody>
                            {loading ? (
                               <tr>
                                    <td colSpan={6} className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                stockTakeLineState && stockTakeLineState.length > 0 && stockTakeLineState.map((stakeline: IStockTakeLine) => (
                                    <tr key={stakeline.id}>
                                        <td>{stakeline.item_code}</td>
                                        <td>{formatDateFull(stakeline.created)}</td>
                                        <td>{stakeline.qty}</td>
                                    </tr>
                                ))
                             )}
                            </tbody>
                          </table>
                      }
                  </div>
                </CardBody>


                 {/* Modal structure */}
                 <>
                 <Modal show={showModal} onHide={() => {
                      setShowModal(false); 
                      // setSelectedStockTake(null);
                      }}>
                            <Modal.Header closeButton>
                            <Modal.Title>{props?.isCycleCount ? "Cycle Count" : "Stock Take" }: [ {selectedStockTake?.reference} ] </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{ fontSize: "15px", paddingLeft: "4px", fontWeight: "900" }}><b>{`"Are you sure you want to delete this ${props?.isCycleCount ? "cycle count" : "stock take"} record?"`}</b></p>   
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>Reference</b></th>
                                            <th><b>Location</b></th>
                                            <th><b>Status</b></th>
                                            <th><b>Created</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          <tr>
                                            <td>{selectedStockTake?.reference}</td>
                                            <td>{selectedStockTake?.location}</td>
                                            <td className={`text-white text-center ${selectedStockTake?.status === "OPEN" ? "bg-success" : selectedStockTake?.status === "CLOSED" ? "bg-warning" : ""}`}>
                                              {selectedStockTake?.status}
                                            </td>
                                            <td>{selectedStockTake?.created}</td>
                                        </tr>
                                    </tbody>
                                </table>       
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" onClick={() => {setShowModal(false); handleDeleteSubmit(selectedStockTake?.id ?? ""); } }>
                                Confirm
                            </Button>
                            <Button variant="secondary" onClick={() => {
                              setShowModal(false);  
                              // setSelectedStockTake(null);
                              } }>
                                Cancel
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </> 


                  {/* Modal structure Closing Record */}
                  <>
                 <Modal show={showCloseRecordModal} onHide={() => {
                      setShowCloseRecordModal(false); 
                      setComment("");
                      }}>
                            <Modal.Header closeButton>
                            <Modal.Title>{props?.isCycleCount ? "Cycle Count" : "Stock Take" }: [ {selectedStockTake?.reference} ] </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{ fontSize: "15px", paddingLeft: "4px", fontWeight: "900" }}><b>{`"Are you sure you want to close this ${props?.isCycleCount ? "cycle count" : "stock take"} record?"`}</b></p>   
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>Reference</b></th>
                                            <th><b>Location</b></th>
                                            <th><b>Status</b></th>
                                            <th><b>Created</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          <tr>
                                            <td>{selectedStockTake?.reference}</td>
                                            <td>{selectedStockTake?.location}</td>
                                            <td className={`text-white text-center ${selectedStockTake?.status === "OPEN" ? "bg-success" : selectedStockTake?.status === "CLOSED" ? "bg-warning" : ""}`}>
                                              {selectedStockTake?.status}
                                            </td>
                                            <td>{selectedStockTake?.created}</td>
                                        </tr>
                                    </tbody>
                                </table>   
                                <hr/> 
                                <p style={{ fontSize: "15px", paddingLeft: "4px", fontWeight: "900" }}><b>{`"Would you like to add a comment for this ${props?.isCycleCount ? "cycle count" : "stock take"} record?"`}</b></p>  
                                <textarea
                                  ref={commentRef}
                                  value={comment}
                                  className="form-control"
                                  onChange={handleChange}
                                  placeholder="Add your comment here..."
                                  style={{width: "100%", fontSize:"15px"}}
                                /> 
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" onClick={() => {setShowCloseRecordModal(false); handleCloseSubmit(selectedStockTake?.id ?? "")  } }>
                                Confirm
                            </Button>
                            <Button variant="secondary" onClick={() => {
                              setShowCloseRecordModal(false);  
                              setComment("");
                              } }>
                                Cancel
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </> 


                 {/* Modal Report Preview */}
                 <>
                 <Modal show={showReportPreviewModal} onHide={() => {
                  setShowReportPreviewModal(false); 
                  setSelectedReportReview([]);
                  // setSelectedStockTake(null);
                  }} 
                          size="xl" 
                          aria-labelledby="example-custom-modal-styling"
                          className="custom-modal-width">
                        <Modal.Header closeButton>
                        <Modal.Title>
                           {props?.isCycleCount ? "Cycle Count" : "Stock Take"}: [ {selectedStockTake?.reference} ] - Report Preview 
                           <button 
                              className="btn btn-secondary" 
                              style={{ margin: "0px 0px 0px 21px"}} 
                              disabled={downLoadLoading}
                              onClick={() => {
                                if(selectedStockTake) handleReportDownLoad(selectedStockTake);
                              }}
                            >
                            Download Report
                          </button>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {downLoadLoading && 
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>
                                <div style={{ width: "100%", textAlign: "center" }}>
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                </div>
                                <div className="report-downloading-message-display" style={{ width: "100%", textAlign: "center" }}>
                                  <span className="download-message" style={{ color: "green" }}>
                                     Processing your report for download...
                                  </span>
                                </div>
                              </div>                              
                            }
                            { showErrorAlert && <ShowErrorAlert message={responseErrorMessage} /> } 
                            <table className="table table-bordered">
                                <thead>
                                      <tr>
                                          <th></th>
                                          <th><b className='report-th-bolder'>TOTALS:</b></th>
                                          <th className='report-th-not-bold'>{totalOnHandStart}</th>
                                          <th className='report-th-not-bold'>{totalCommittedStart}</th>
                                          <th className='report-th-not-bold'>{totalAvailableStart}</th>
                                          <th className='report-th-not-bold'>{totalScannedQty}</th>
                                          <th className='report-th-not-bold'>{totalScannedVarience}</th>
                                          <th className='report-th-not-bold'>{totalOnHandFinal}</th>
                                          <th className='report-th-not-bold'>{totalCommittedFinal}</th>
                                          <th className='report-th-not-bold'>{totalActaulVarience}</th>
                                      </tr>
                                      <tr>
                                          <th><b className='report-th-bolder'>Sku</b></th>
                                          <th><b className='report-th-bolder'>Description</b></th>
                                          <th><b className='report-th-bolder'>On Hand (Start)</b></th>
                                          <th><b className='report-th-bolder'>Committed (Start)</b></th>
                                          <th><b className='report-th-bolder'>Available (Start)</b></th>
                                          <th><b className='report-th-bolder'>Scanned Qty</b></th>
                                          <th><b className='report-th-bolder'>Scanned Varience</b></th>
                                          <th><b className='report-th-bolder'>On Hand (Final)</b></th>
                                          <th><b className='report-th-bolder'>Committed (Final)</b></th>
                                          <th><b className='report-th-bolder'>Actaul Varience</b></th>
                                      </tr>
                                </thead>
                                <tbody>
                                {reportLoading ? (
                                    <tr>
                                          <td colSpan={10} className="text-center">
                                              <div className="spinner-border" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                              </div>
                                          </td>
                                      </tr>
                                  ) : (
                                    selectedReportReview && selectedReportReview.map((report:IStockReportReview) => (
                                      <tr key={report.item_code}>
                                         <td>{report.item_code}</td>
                                         <td>{report.name}</td>
                                         <td>{report.on_hand_start}</td>
                                         <td>{report.committed_start}</td>
                                         <td>{report.available_start}</td>
                                         <td>{report.scanned_qty}</td>
                                         <td>{report.scanned_variance}</td>
                                         <td>{report.on_hand_final}</td>
                                         <td>{report.committed_final}</td>
                                         <td>{report.actual_variance}</td>
                                      </tr>
                                     ))
                                  )}
                                </tbody>
                            </table>       
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                          setShowReportPreviewModal(false);  
                          setSelectedReportReview([]);
                          // setSelectedStockTake(null);
                          }}>
                            Cancel
                        </Button>
                        </Modal.Footer>
                    </Modal>
                  </> 


        </Card>
    </>
  )
}

export default StockTake;