import { ChangeEvent, useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { Button, Card, Container, Modal } from "react-bootstrap";
import React from "react";
import { Search, X } from "lucide-react";
import { CastType, IQuery, IWhere } from "../../../../component/solution/Interfaces";
import { formatDateFull } from "../../../../component/solution/utils/AppUtil";

export const WholesaleMovementOut: React.FC = () => {

    const {fetchData, token}  = useAuth();
        const [resultElements, setResultElements] = useState<React.ReactElement[]>([]);
        const [detailElements, setDetailElements] = useState<React.ReactElement[]>([]);
        // Search
        const [search, setSearch] = useState<Map<string, string>>(new Map<string, string>());
        // Modal Show & Close
        const [showModal, setShowModal] = useState(false);
        const [docNo, setDocNo] = useState<number>(0);

        const queryUrl: string = `${process.env.REACT_APP_BASE_URL}/${"query/data"}`;
        const [queryState, setQueryState] = useState<IQuery>(
            {
                tableName: "sa_m24l_pick_list",
                select: [],
                groupBy: [],
                join: [],
                orderBy: [ 
                    {
                        fullColumnName: "doc_num",
                        direction: "DESC",
                        cast: CastType.INTEGER
                    }
                ],
                where: [],
                mainTableOnly: false,
                includeExtraColumns: false,
                includeControlColumns: true,
                modifiedAfter: null,
                touchedBefore: null,
                isDirty: null,
                pageNum: 0,
                pageSize: 50,
                frontEndOnly: null
            }    
        );
    useEffect(() => {
        fetchRetailMovementOut();       
    }, [queryState]);

    useEffect(() => {
        const searchFields = new Map<string, string>()
        searchFields.set("doc_num", "");
        searchFields.set("order_doc_num", "");
        searchFields.set("card_code", "");
        searchFields.set("card_name", "");
        searchFields.set("integration_status", "");
        searchFields.set("num_at_card", "");
        searchFields.set("original_warehouse", "");
        searchFields.set("warehouse_code", "");
        searchFields.set("vas_code", "");
        setSearch(searchFields);        
    }, []);

    const processResult = (result: any) => {
        const shipments: any[] = result.data;

        // Build React elements and set state
        const resultElements: React.ReactElement[] = shipments.map((record, index) => (
            <tr id={record.data?.doc_num} onClick={() => fetchDocumentDetail(record.data?.doc_num)} key={index} className="record-line">
                <td>{record.data?.doc_num}</td>
                <td>{record.data?.order_doc_num}</td>
                <td>{record.data?.card_code}</td>
                <td>{record.data?.card_name}</td>
                { record.data?.integration_status === "ERROR" ? 
                    (<td className="bg-danger text-dark text-center">{record.data?.integration_status}</td>) :
                    (<td className="bg-success text-dark text-center">{record.data?.integration_status}</td>)
                }
                <td>{record.data?.num_at_card}</td>
                <td>{record.data?.original_warehouse}</td>
                <td>{record.data?.warehouse_code}</td>
                <td>{record.data?.vas_code}</td>                
                <td style={{whiteSpace: "nowrap"}}>{formatDateFull(record.control?.created)}</td>
                <td style={{whiteSpace: "nowrap"}}>{formatDateFull(record.control?.updated)}</td>
            </tr>
        ));

        setResultElements(resultElements);
    }

    const fetchRetailMovementOut = () => {
        fetchData(queryUrl, {method:"POST", body: JSON.stringify(queryState)}, processResult);        
    }   

    const fetchDocumentDetail = async (docNo: number) => {
        
        setDocNo(docNo);

        const detailQueryState: IQuery = {
            tableName: "sa_m24l_pick_list_line",
            select: [
            ],
            groupBy: [],
            join: [],
            orderBy: [
                {
                    fullColumnName: "item_code",
                    direction: "ASC",
                    cast: CastType.STRING
                }
            ],
            where: [
                {
                    fullColumnName: "doc_num",
                    value: docNo,
                    operator: "EQ",
                    cast: CastType.STRING
                }
            ],
            mainTableOnly: false,
            includeExtraColumns: false,
            includeControlColumns: true,
            modifiedAfter: null,
            touchedBefore: null,
            isDirty: null,
            // pageNum: 0,
            // pageSize: 20,
            frontEndOnly: null
        }

        fetchData(queryUrl, {method:"POST", body: JSON.stringify(detailQueryState)}, processDetail);
        setShowModal(true);
    }

    const processDetail = (result: any) => {


        const detail: any[] = result.data.sort((a: any, b: any) => a.data.line_num - b.data.line_num);
        const detailElements: React.ReactElement[] = [];
        const detailTotalElements: React.ReactElement[] = [];

    
        let totalOrdered: number = 0;
        let totalSent: number = 0;
        for(const key in detail){
            //console.log(shipmentSummary[key].data?.item_code)
            totalOrdered += detail[key].data?.qty_ordered;
            totalSent += detail[key].data?.qty_sent;
            if(detail[key].data?.qty_ordered === detail[key].data?.qty_sent) {
                detailElements.push(
                    <tr key={key}>
                        <td>{detail[key].data?.line_num}</td>
                        <td>{detail[key].data?.item_code}</td>
                        <td className="text-center">{detail[key].data?.qty_ordered}</td>
                        <td className="text-center">{detail[key].data?.qty_sent}</td>
                    </tr>
                );
            } else {
                detailElements.push(
                    <tr key={key}>
                        <td className="bg-danger text-white fw-bold text-center">{detail[key].data?.line_num}</td>
                        <td className="bg-danger text-white fw-bold text-center">{detail[key].data?.item_code}</td>
                        <td className="bg-danger text-white fw-bold text-center">{detail[key].data?.qty_ordered}</td>
                        <td className="bg-danger text-white fw-bold text-center">{detail[key].data?.qty_sent}</td>
                    </tr>
                );                
            }           
        }

        detailTotalElements.push(
            <>
                <tr key={detail.length}>
                    <td><b></b></td>
                    <td><b></b></td>
                    <td><b>Total Ordered:</b></td>
                    <td><b>Total Sent:</b></td>
                </tr>            
                <tr key={detail.length + 1}>
                    <td><b></b></td>
                    <td><b></b></td>                    
                    {totalOrdered !== totalSent ? (
                            <>
                                <td className="bg-danger text-white fw-bold text-center">{totalOrdered}</td>
                                <td className="bg-danger text-white fw-bold text-center">{totalSent}</td>
                            </>
                        ) : (
                            <>
                                <td className="bg-success text-dark fw-bold text-center">{totalOrdered}</td>
                                <td className="bg-success text-dark fw-bold text-center">{totalSent}</td>                            
                            </>
                        )
                    }

                </tr>
            </>
        );
    
        setDetailElements([...detailElements, ...detailTotalElements ]);
    } 

    const searchClicked = () => {
        let where: IWhere[] = [];

        search.forEach((value: string, column: string) => {
            console.log(column + " : " + value);
            if(value && value.length){
                const clause: IWhere = {
                    fullColumnName: column,
                    value: '%' + value + '%',
                    operator: "LIKE",
                    cast: CastType.STRING
                }
                where.push(clause);
            }
        });    

        const query = {...queryState}
        query.where = where;
        query.pageNum = 0;
        setQueryState(query);
        console.log(query);
    }

    const clearClicked = () => {

        const search = new Map<string, string>()

        search.set("doc_num", "");
        search.set("order_doc_num", "");
        search.set("card_code", "");
        search.set("card_name", "");
        search.set("integration_status", "");
        search.set("num_at_card", "");
        search.set("original_warehouse", "");
        search.set("warehouse_code", "");
        search.set("vas_code", "");

        setSearch(search);

        let where: IWhere[] = [];

        search.forEach((value: string, column: string) => {
            console.log(column + " : " + value);
            if(value && value.length){
                const clause: IWhere = {
                    fullColumnName: column,
                    value: '%' + value + '%',
                    operator: "LIKE",
                    cast: CastType.STRING
                }
                where.push(clause);
            }
        });  

        const query = {...queryState}
        query.where = where;
        setQueryState(query);      
    }
    
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.type === 'text') {
            setSearch(prevSearch => {
                const newSearch = new Map(prevSearch);
                newSearch.set(event.target.id, event.target.value);
                return newSearch;
            });
        }
    } 

    return (
        <>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Card.Title>{"Outbound Wholesale"}</Card.Title>
                    </Card.Header>

                    <div className="ms-4 me-4" style={{}}>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {Array.from(search).map(([key, value]) => (
                                        <td>
                                            <input id={key} name={key} value={value} onChange={handleOnChange} type="text" autoComplete="off" placeholder={`Search ${key}...`} className="form-control form-control-md"></input>
                                        </td>
                                    ))}
                                    <td colSpan={2}><Search color="#c84338" onClick={searchClicked} className="custom-icon text-center align-middle" /><X color="#c84338" onClick={clearClicked} style={{width: "36px", height: "36px"}} className="custom-icon text-center align-middle ms-5"/></td>
        
                                </tr>                              
                                <tr>                  
                                    <td><b>DocNo</b></td>
                                    <td><b>OrderDocNo</b></td>
                                    <td><b>CardCode</b></td>
                                    <td><b>CardName</b></td>
                                    <td><b>Status</b></td>
                                    <td><b>NumAtCard</b></td>
                                    <td><b>OrigWarehouse</b></td>
                                    <td><b>WarehouseCode</b></td>
                                    <td><b>VASCode</b></td>
                                    <td><b>Created</b></td>
                                    <td><b>Updated</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {resultElements}                                                                                                                                                              
                            </tbody>
                        </table>
                    </div>

                    <>
                        {/* Modal structure */}
                        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                            <Modal.Header closeButton>
                            <Modal.Title>Doc Num: {docNo}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p><b>Shipment Confirmation:</b></p>            
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td><b>Line No.</b></td>
                                            <td><b>Item Code</b></td>
                                            <td><b>Quantity Ordered</b></td>
                                            <td><b>Quantity Sent</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailElements}
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </>                    

                </Card>
            </Container>
        </>
    )
}