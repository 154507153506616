import { useAuth } from "../contexts/AuthContext";

interface ShowErrorAlertProps {
    message: string | null;
}

const ShowErrorAlert: React.FC<ShowErrorAlertProps>  = ({ message }) => {
    const { handleCloseErrorAlert }  = useAuth();

    return (
    <div id="error-alert" role="alert" style={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '2px', backgroundColor: '#fef2f2', color: '#e53e3e', borderRadius: '8px', border: '1px solid #e53e3e' }}>
        <svg style={{ flexShrink: 0, width: '16px', height: '16px' }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <div style={{ marginLeft: '12px', fontSize: '14px', fontWeight: '500' }}>
         { message }
        </div>
        <button type="button" style={{ marginLeft: 'auto', marginTop: '-6px', marginBottom: '-6px', backgroundColor: '#fef2f2', color: '#e53e3e', borderRadius: '8px', padding: '6px', height: '32px', width: '32px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', border: 'none', cursor: 'pointer', transition: 'background-color 0.2s ease' }}
          aria-label="Close"
          onClick={ () => handleCloseErrorAlert()}
        >
        <svg style={{ width: '12px', height: '12px' }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 14 14">
            <path stroke="#e53e3e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
        </button>
    </div>
    );
}

export default ShowErrorAlert;